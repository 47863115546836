import React, {Component} from 'react';
import '../App.css';
import { animateScroll as scroll } from "react-scroll";

export default class ScrolltoTop extends Component{
    scrollToTop = () => {
        scroll.scrollToTop();
    };

    render(){
        return(
            <a className="scroll-to-top rounded js-scroll-trigger" style={{display:"inline", color:"#FFFFFF"}} onClick={this.scrollToTop}>
                <i className="fa fa-angle-up"></i>
            </a>
        );
    }
}