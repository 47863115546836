import React, { Component } from 'react';
import { Container } from 'reactstrap'

export default class Footer extends Component{
    render(){
        return(
            <Container style={{paddingTop:"calc(100vh - 90vh)"}}>
                <p className="small">Copyright © Sunny Leung 2024</p>
                <p className="small" style={{bottom:'2vh'}}>This website is re-written in REACT.js</p>      
            </Container> 
        );
    }
}