import React, {Component} from 'react';
import '../../node_modules/font-awesome/css/font-awesome.min.css'; 
import { Container } from 'reactstrap';
import "../App.css";

export default class ContactItems extends Component{
    render(){
        const icons = ["fa fa-linkedin so-icons fa-2x", "fa fa-twitter so-icons fa-2x", "fa fa-github so-icons fa-2x", "fa fa-file so-icons fa-2x"];
        const names = ["LinkedIn", "Twitter", "Github", "Resume"];
        const links = ["https://www.linkedin.com/in/sunny-leung-0a1422b6/", "https://twitter.com/leungs420", "https://github.com/leungs420", 
            "https://www.sunnyleungkc.com/projects/resume.pdf"];
        return(
            <Container className="text-center">
                <div style={{padding:'2vh'}}></div>
                <h6>Email: <a href="mailto:contact@sunnyleungkc.com" style={{color:"#FFFFFF"}}>
                contact@sunnyleungkc.com</a></h6>

                <div style={{padding:'1vh'}}></div>
                <ul className="list-inline mb-5" id="contact-list">
                    {icons.map((icon, i) => 
                    <li className="list-inline-item" key={"item-"+i}>
                        <a className="social-link rounded-circle text-white mr-3 ml-3" href={links[i]} rel="noopener noreferrer" target="_blank">
                            <i className={icon}></i>
                        </a>
                        <p>{names[i]}</p>
                    </li>)}
                </ul>
                
            </Container>
        );
    }
}