import React, { Component } from 'react';
import './App.css';
import LandingPage from './components/landingpage';
import About from './components/about';
import Portfolio from './components/portfolio';
import Contact from './components/contact';
import Nav from './components/nav';
import ScrolltoTop from './components/scrolltotop';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Skills from './components/skills';

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <Nav/>
        <LandingPage/>
        <About/>
        <Skills/>
        <Portfolio/>
        <Contact/>
        <ScrolltoTop/>
      </div>
    );
  }
}