import React, {Component} from 'react';
import '../App.js';
import '../../node_modules/font-awesome/css/font-awesome.min.css'; 
import { Link } from "react-scroll";
import ContactItems from './contactitems.js';
import Footer from './footer';

export default class Contact extends Component{
    render(){
        return(
            <div className='contact content-section text-white' id='contact' style={{height:'100%', paddingBottom:'0px'}}>
                <h5 className="mb-0 titles"><b>Contact</b></h5>
                <h3 className='text-white' style={{marginTop:'0px'}}><b>Let's Keep in Touch!</b></h3>
                <ContactItems/>
                <div style={{padding:'5vh'}}>
                    <Link activeClass="active" to="home" spy={true}
                        smooth={true}
                        duration={500}
                        className="btn btn-dark btn-xl"
                        style={{padding:'20px 40px'}}><b>Back to top</b>
                    </Link>   
                </div>
                <Footer/>
            </div>
        );
    }
}