import React, {Component} from 'react';
import '../App.js';
import '../../node_modules/font-awesome/css/font-awesome.min.css'; 
import PortfolioItems from './portfolioitems';
import { Link } from "react-scroll";

export default class Portfolio extends Component{
    render(){
        return(
            <div className='portfolio content-section' id='portfolio'>
                <h5 className='mb-0 titles'><b>Portfolio</b></h5>
                <h2 className="mb-5" style={{marginTop:'0px'}}><b>Recent Projects</b></h2>
                <PortfolioItems/>
                
                <div style={{padding:'5vh'}}></div>
                <Link activeClass="active" to="contact" spy={true}
                    smooth={true}
                    duration={500}
                    className="btn btn-dark btn-xl"
                    style={{padding:'20px 40px'}}><b>Contact Me</b>
                </Link>
            </div>
        );
    }
}