import React, {Component} from 'react';
import { Layout } from 'react-mdl';
import { Link } from "react-scroll";

export default class LandingPage extends Component{
    render(){
        return(
            <div className="home" id='home' style={{height: '100vh', position: 'relative'}}>
                <Layout style={{background: 'url(https://sunnyleungkc.com/img/bg-front.jpg) center / cover'}}>
                    <div className="container text-center my-auto">
                        <h1 className="mb-1"><b>Sunny Leung</b></h1>
                        <h4 className="mb-5" style={{marginTop:'0'}}>
                            <em><strong>A Passionate Leader/Developer/Programmer</strong></em>
                        </h4>
                        <div style={{padding:'5vh'}}></div>
                        <Link activeClass="active" to="about" spy={true}
                            smooth={true}
                            duration={500}
                            className="btn btn-dark btn-xl" 
                            style={{padding:'20px 40px'}}><b>Find Out More About Me</b>
                        </Link>
                        
                    </div>
                    <div className="overlay"></div>
                </Layout>
            </div>
        );
    }
}