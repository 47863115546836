import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../App.css';

export default class PortfolioItems extends Component{
    render(){
        const links = ["https://www.sunnyleungkc.com/survey", "https://www.sunnyleungkc.com/projects/ba_report.pdf", "https://www.sunnyleungkc.com/book/",
        "https://www.sunnyleungkc.com/projects/4610.pdf", "https://www.sunnyleungkc.com/projects/nn.html"];
        const descriptions = ["A survey site just for you!", "A Research Done with Machine Learning Methods", "A Neat, Responsive, User-friendly website created for Aaron Sheppard",
        "Exploring the Possibility of Identifying Social Movement Trends Using Data Mining Strategies on Distinct Packets", "This Program Identifies Whether the Given Image is 1 or 2"];
        const icons = ["https://sunnyleungkc.com/img/survey.png", "https://sunnyleungkc.com/img/ba_paper.png", "https://sunnyleungkc.com/img/aaron.png",
        "https://sunnyleungkc.com/img/4610.png", "https://sunnyleungkc.com/img/nn.png"];
        const names = ["SunnyD", "Keystroke Dynamics", "Aaron Sheppard's Book Site", "Social Engineering", "Neural Network Image Recognition"];

        return(
            <Container>
                <Row className="no-gutters">
                {links.map((link, i) => 
                <Col lg="6" key={"portfolio-"+i}>
                    <a className="portfolio-item" href={link} rel="noopener noreferrer" target="_blank">
                        <span className="caption">
                            <span className="caption-content">
                                <h2>{names[i]}</h2>
                                <p className="mb-0">{descriptions[i]}</p>
                            </span>
                        </span>
                        <img className="img-fluid" src={icons[i]} alt=""/>
                    </a>
                </Col>)}
                
                <Col lg="6">
                    <a className="portfolio-item">
                        <span className="caption">
                            <span className="caption-content">
                                <h2>Stay Tuned</h2>
                                <p className="mb-0">More Coming Up...</p>
                            </span>
                        </span>
                        <img className="img-fluid" src="https://sunnyleungkc.com/img/more.png" alt=""/>
                    </a>
                </Col>
                </Row>
            </Container>
        );
    }
}