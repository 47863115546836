import React, {Component} from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import "../App.css";

function clickNav(e){
    var nav = document.getElementById("sidebar-wrapper");
    var icon = document.getElementById("sidebar-icon");

    if(nav.classList.contains("active")){
        nav.classList.remove("active");
        var iconChange = document.getElementsByClassName("fa-times")[0];
        iconChange.classList.add("fa-bars");
        iconChange.classList.remove("fa-times");
        icon.classList.remove("active");
    }else{
        nav.classList.toggle("active");
        var iconChanges = document.getElementsByClassName("fa-bars")[0];
        iconChanges.classList.add("fa-times");
        iconChanges.classList.remove("fa-bars");
        icon.classList.toggle("active");
    }
}

function changeTheme(e){
    //e.preventDefault();
    clickNav(e);
    var storage;
    if(localStorage.theme === null || typeof localStorage.theme === 'undefined'){
        localStorage.theme = 1;
        storage = 1;
    }else{
        if(localStorage.temp === localStorage.theme){
            storage = parseInt(localStorage.theme) + 1;
            
            if(storage === 3){
                storage = 0;
            }
            
            localStorage.theme = storage;
        }else{
            storage = parseInt(localStorage.theme) + 1;
            if(storage === 3){
                storage = 0;
            }
            localStorage.theme = storage;
        }
    }

    if(storage === 1){
        var nav = document.getElementById("sidebar-wrapper");
        nav.classList.add("sidebar-wrapper1");
        var skills = document.getElementById("skills");
        skills.classList.add("skills1");
        var serviceIcons = document.getElementsByClassName("service-icon");
        var serviceSize = serviceIcons.length;

        for(var i=0;i<serviceSize;i++){
            serviceIcons[i].classList.add("service-icon1");
        }

        var portfolioItems = document.getElementsByClassName("portfolio-item");
        var portfolioItemsSize = portfolioItems.length;
        for(var j=0;j<portfolioItemsSize;j++){
            portfolioItems[j].classList.add("portfolio-item1");
        }

        var contact = document.getElementById("contact");
        contact.classList.add("contact1");

        var contactList = document.getElementById("contact-list");
        contactList.classList.add("list-inline1");

        var socialLinks = document.getElementsByClassName("social-link");
        var socialLinksSize = socialLinks.length;
        var soIcons = document.getElementsByClassName("so-icons");
        for(var k=0;k<socialLinksSize;k++){
            socialLinks[k].classList.add("social-link1");
            soIcons[k].classList.add("so-icons1");
        }
        //localStorage.theme = 2;
    }
    else if(storage === 0){
        var home = document.getElementById("home");
        home.classList.remove("text-white");
        home.classList.remove("home2");

        var home1 = document.getElementsByClassName("mdl-layout")[0];
        home1.style.background = "url('https://sunnyleungkc.com/img/bg-front.jpg') center center / cover";

        var nav = document.getElementById("sidebar-wrapper");
        nav.classList.remove("sidebar-wrapper2");

        var about = document.getElementById("about");
        about.classList.remove("about2");
        about.classList.remove("text-white");
        about.classList.add("bg-light");

        var skills = document.getElementById("skills");
        skills.classList.remove("skills2");
        var serviceIcons = document.getElementsByClassName("service-icon");
        var serviceSize = serviceIcons.length;

        for(var i=0;i<serviceSize;i++){
            serviceIcons[i].classList.remove("service-icon1");
        }

        var portfolio = document.getElementById("portfolio");
        portfolio.classList.remove("portfolio2");
        portfolio.classList.remove("text-white");

        var portfolioItems = document.getElementsByClassName("portfolio-item");
        var portfolioItemsSize = portfolioItems.length;
        for(var j=0;j<portfolioItemsSize;j++){
            portfolioItems[j].classList.remove("portfolio-item1");
        }

        var contact = document.getElementById("contact");
        contact.classList.remove("contact2");

        var contactList = document.getElementById("contact-list");
        contactList.classList.remove("list-inline2");

        var socialLinks = document.getElementsByClassName("social-link");
        var socialLinksSize = socialLinks.length;
        var soIcons = document.getElementsByClassName("so-icons");
        var soIconsSize = soIcons.length;
        for(var k=0;k<socialLinksSize;k++){
            socialLinks[k].classList.remove("social-link1");
            socialLinks[k].classList.remove("social-link2");
            soIcons[k].classList.remove("so-icons1");
            soIcons[k].classList.remove("so-icons2");
        }
        //localStorage.theme = 1;
    }
    else if(storage === 2){
        var home = document.getElementById("home");
        home.classList.add("text-white");
        home.classList.add("home2");

        var home1 = document.getElementsByClassName("mdl-layout")[0];
        home1.style.background = "";

        var nav = document.getElementById("sidebar-wrapper");
        nav.classList.add("sidebar-wrapper2");
        nav.classList.remove("sidebar-wrapper1");

        var about = document.getElementById("about");
        about.classList.add("about2");
        about.classList.add("text-white");
        about.classList.remove("bg-light");

        var skills = document.getElementById("skills");
        skills.classList.add("skills2");
        skills.classList.remove("skills1");

        var portfolio = document.getElementById("portfolio");
        portfolio.classList.add("portfolio2");
        portfolio.classList.remove("portfolio1");

        var contact = document.getElementById("contact");
        contact.classList.add("contact2");
        contact.classList.remove("contact1");

        var contactList = document.getElementById("contact-list");
        contactList.classList.add("list-inline2");
        contactList.classList.remove("list-inline1");

        var socialLinks = document.getElementsByClassName("social-link");
        var socialLinksSize = socialLinks.length;
        var soIcons = document.getElementsByClassName("so-icons");
        for(var k=0;k<socialLinksSize;k++){
            socialLinks[k].classList.add("social-link2");
            soIcons[k].classList.add("so-icons2");
        }
        //localStorage.theme = 0;
    }
}

class Nav extends Component{
    scrollToTop = () => {
        scroll.scrollToTop();
    };
    
    render(){
        const header = ["Home", "About Me", "My Skills", "Portfolio", "Contact Information"];
        const links = ["home","about","skills","portfolio","contact"];

        return(
            <div>
                <a className="menu-toggle rounded" id="sidebar-icon" onClick={clickNav} style={{color:"#FFFFFF"}}>
                    <i className="fa fa-bars"></i>
                </a>
                <nav id="sidebar-wrapper" className="">
                    <ul className="sidebar-nav" style={{textAlign:'left'}}> 
                        <li className="sidebar-brand" >
                            <Link activeClass="active" to="home" spy={true}
                                smooth={true}
                                duration={500}
                                style={{cursor:'pointer', color:"#ffffff"}}
                                onClick={clickNav}>Sunny Leung
                            </Link>
                        </li>

                        {header.map((headers, i) => 
                        <li className="sidebar-nav-item" key={"Header"+i}>
                            <Link activeClass="active" to={links[i]} spy={true}
                                smooth={true}
                                duration={500}
                                style={{cursor:'pointer', color:"#ffffff"}}
                                onClick={clickNav}>{headers}
                            </Link>
                        </li>)}
                        
                        <li className="sidebar-nav-item">
                            <a style={{cursor:'pointer', color:"#ffffff"}} onClick={changeTheme}>
                            Change Theme (Beta)
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Nav;