import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import '../App.css';
import { Link } from "react-scroll";

export default class Skills extends Component{
    render(){
        const title = ["Machine Learning", "Web Design", "Android App Development", "Volunteer Work"];
        const description = ["Created Several Simple AI Web Applications.", "HTML5, CSS, PHP, Javascript etc...",
        "Made a few Android Applications with the latest Android technologies.", "I volunteer a lot."];
        const icon = ["fa fa-cogs", "fa fa-pencil", "fa fa-android", "fa fa-male"];
        return(
            <div className="content-section text-white text-center skills" id="skills">
                <Container>
                    <div className="content-section-heading">
                        <h5 className="mb-0 titles"><b>Skills</b></h5>
                        <h2 className="mb-5" style={{marginTop:'0px'}}><b>What Can I Do?</b></h2>
                    </div>
                    <Row>
                        {title.map((titles, i) => 
                            <Col lg="3" md="6" className="mb-5 mb-lg-0" key={"about-"+i}>
                            <span className="service-icon rounded-circle mx-auto mb-3">
                                <i className={icon[i]}></i>
                            </span>
                                <h4 style={{marginBottom:"0px"}}>
                                    <strong>{titles}</strong>
                                </h4>
                                <p className="text-faded mb-0">{description[i]}</p>
                            </Col>
                        )}

                        <Col lg="10" className="mx-auto" style={{padding:"5vh"}}>
                            <Link activeClass="active" to="portfolio" spy={true}
                                smooth={true}
                                duration={500}
                                className="btn btn-dark btn-xl "
                                style={{padding:'20px 40px'}}><b>Check Out My Portfolio</b>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}