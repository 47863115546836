import React, {Component} from 'react';
import '../App.css';
import '../../node_modules/font-awesome/css/font-awesome.min.css'; 
import { Link } from "react-scroll";
import { Container } from 'reactstrap';

export default class About extends Component{
    render(){
        return(
            <div className='content-section bg-light about' id="about">
                <Container className="text-center">
                    
                    <div id='title'>
                        <h3><b>Hi! My name is Sunny Leung :)</b></h3>
                    </div>
                    <div>
                        <p className="lead mb-5">the Engineering Manager at Trip Ninja. With a vibrant journey from being a Senior Software Developer to now leading a dynamic team, I blend a deep-rooted passion for technology with a commitment to guiding and mentoring upcoming talents in the tech world.</p> 
                    </div>
                    <div style={{padding:'5vh'}}></div>
                    <Link activeClass="active" to="skills" spy={true}
                        smooth={true}
                        duration={500}
                        className="btn btn-dark btn-xl"
                        style={{padding:'20px 40px'}}><b>What I Do</b>
                    </Link>
                </Container>
            </div>
        );
    }
}